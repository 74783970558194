import React from 'react';
import {Helmet} from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {graphql} from "gatsby";
import HeroVideo from "../components/GeneralComponents/HeroVideo";
import NoticiasGrid from "../components/Noticias/NoticiasGrid";

const Noticias = ({data}) => {
    return(
        <>
            <Helmet>
                <html lang="pt-BR" amp />
                <meta charSet="UTF-8" />
                <title>Cia. Ibérica - Notícias</title>
                <link rel="canonical" href="https://www.ciaiberica.com.br/noticias/" />
                <meta name="robots" content="index,follow,max-snippet: -1,max-image-preview:large" />
                <meta property="og:locale" content="pt-BR" />
                <meta property="og:title" content="Cia. Ibérica - Notícias" />
                <meta property="og:description" content="Confira as notícias publicadas pela Cia. Ibérica, empresa brasileira e com tradição no mercado." />
                <meta property="og:url" content="https://www.ciaiberica.com.br/noticias/" />
                <meta property="og:sitename" content="Cia. Ibérica" />
                <meta property="article:publisher" content="https://facebook.com/ibericacondutoreseletricosltda" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            </Helmet>
            <Header />
            <HeroVideo props={data} />
            <NoticiasGrid props={data}/>
            <Footer />
        </>
    )
}

export const query = graphql`
    query NoticiasQuery {
        noticias: allContentfulNoticias(filter: {node_locale: {eq: "en-US"}}) {
            edges {
                node {
                    imagemDestacada {
                        gatsbyImageData(quality: 100, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                    }
                    urlNoticia
                    titulo
                }
            }
        }
        video: contentfulAsset(title: {eq: "noticias"}) {
            file {
              url
              contentType
            }
        }
        poster: contentfulAsset(title: {eq: "noticias-poster"}) {
            file {
              url
              contentType
            }
        }
    }
`

export default Noticias;